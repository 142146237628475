<template>
  <div>
    <Progress v-if="loading"/>
      <v-dialog
      v-model="dialog_loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Please stand by
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-card
    tile
    v-if="!loading"
    >
    <v-card-text class="text-center">
        <v-container>
           <v-row justify="center" align="center">
                <v-col
                    cols="9"
                >
                <v-row>
                  <v-col cols="4">
                    <v-menu
                      v-model="from_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="from"
                          label="From"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="from"
                        @input="from_menu = false"
                        @change="go_data()"
                        :max="to"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-menu
                      v-model="to_menu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="to"
                          label="To"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          prepend-icon="mdi-calendar"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="to"
                        @input="to_menu = false"
                        @change="go_data()"
                        :min="from"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="4">
                    <v-select
                    :items="branchs"
                    v-model="branch_id"
                    label="Branch"
                    item-value="id"
                    item-text="name"
                    @input="go_data()"
                  ></v-select>
                  </v-col>
                </v-row>
                </v-col>

                <v-col
                    cols="3"
                    align="right"
                >
                <download-excel :data="data" style="display:inline-block"
                :title="'Report Purchase from ' + from + ' to ' + to" :name="'Report Purchase from ' + from + ' to ' + to +'.xls'">
                <v-btn color="success" small depressed class="mr-2">
                  <v-icon small>mdi-microsoft-excel</v-icon>
                </v-btn>
                </download-excel>
                          <v-btn color="red" small depressed @click="downloadPdf()">
                        <v-icon small color="white">mdi-file-pdf-outline</v-icon>
                    </v-btn>

                </v-col>
            </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="report_purchase"
          :page.sync="page"
          :search="search"
          :items-per-page="itemsPerPage"
          hide-default-footer
          class="elevation-1"
          @page-count="pageCount = $event"
          mobile-breakpoint="0"
          @click:row="openDetail"
        >
          <template v-slot:item.discount="{ item }">
            Rp. {{item.discount | numeral('0,0')}}
          </template>
          <template v-slot:item.total="{ item }">
            Rp. {{item.total  | numeral('0,0')}}
          </template>
        </v-data-table>
        <div class="text-center pt-2">
          <v-pagination
            v-model="page"
            :length="pageCount"
          ></v-pagination>
          <v-text-field
            :value="itemsPerPage"
            label="Items per page"
            type="number"
            min="-1"
            max="15"
            @input="itemsPerPage = parseInt($event, 10)"
          ></v-text-field>
        </div>
    </v-card-text>
    </v-card>
     <vue-html2pdf
      ref="html2Pdf"
      :paginate-elements-by-height="1400"
      pdf-content-width="1100px"
      pdf-orientation="landscape"
      :filename="`Report Purchase form ${from} to ${to}`"
    >
    <templatePDF :headers="['Document','Date','Total','Payment','Branch','Name','Note']"
    :title="`Report from ${from} to ${to}`"
    slot="pdf-content"
     >
    <tr v-for="item in data" :key="item.Document">
      <td>{{item.Document}}</td>
      <td>{{item.Date}}</td>
      <td>Rp. {{item.Total | numeral('0,0')}}</td>
      <td>{{item.Payment}}</td>
      <td>{{item.Branch}}</td>
      <td>{{item.Name}}</td>
      <td>{{item.Note}}</td>
    </tr>
    </templatePDF>
    </vue-html2pdf>

    <dialogDetail :data="data_detail" v-if="dialog_detail" v-on:close="close"/>
  </div>
</template>
<script>
// @ is an alias to /src
import Progress from '../../../components/external/Progress'
import { mapActions, mapGetters } from 'vuex'
import downloadExcel from "vue-json-excel";
import VueHtml2pdf from 'vue-html2pdf'
import templatePDF from '../../../components/external/report-pdf'
import dialogDetail from './components/detail'
export default {
  data () {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      search: '',
      dialog_loading:false,
      dialog_detail:false,
      data_detail:{},
      data:[],
      from:new Date().toISOString().substr(0, 10),
      to:new Date().toISOString().substr(0, 10),
      from_menu:false,
      to_menu:false,
      loading:true,
      branch_id:'',
      headers: [
        {
          text: 'Document',
          align: 'start',
          value: 'doc_no'
        },
        {
          text: 'Partner',
          value: 'partner.name'
        },
        {
          text: 'Date',
          value: 'doc_date'
        },
        {
          text: 'Disc',
          value: 'discount'
        },
        {
          text: 'Total',
          value: 'total'
        },
      ]
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
      report_purchase: 'data/report_purchase',
      branchs: 'data/branchs'
    })
  },
  components: {
    Progress,downloadExcel,VueHtml2pdf,templatePDF,dialogDetail
  },
  methods: {
    ...mapActions({
      setData: 'data/setData',
      setSnakbar:'snakbar/setSnakbar',
      setEditData:'cart/setEditData',
      setCart:'cart/setCart'
    }),
    async downloadPdf() {
      this.dialog_loading = true
      await this.$refs.html2Pdf.generatePdf();
      this.dialog_loading = false
    },
    openDetail: function (item, b, event) {
      this.data_detail = item
      this.close()
    },
    close () {
      this.dialog_detail = !this.dialog_detail
    },
    async go_data () {
      console.log(this.from)
      await this.axios.get('/v1/purchase/report?company_id=' + this.user.company_id + `&branch_id=${this.branch_id}&from=${this.from}&to=${this.to}` ,)
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: 'report_purchase' })
          this.data = await this.report_purchase.map(x => {
            let value = {
              Document:x.doc_no,
              Date:x.doc_date,
              Total:parseInt(x.total),
              Partner:x.partner.name,
              Payment:x.payment.name,
              Branch:x.branch.name,
              Name:x.name,
              Note:x.note,
            }
            return value
          })
        })
        .catch(err => console.log(err))
      this.loading = false
    },
    async go (table,store) {
      await this.axios.get('/v1/'+ table +'/all?company_id=' + this.user.company_id, {
        headers: {
          Authorization: 'Bearer ' + this.user.token
        }
      })
        .then(async (ress) => {
          await this.setData({ data: ress.data.data, table_name: store })
        })
        .catch(err => console.log(err))
      this.loading = false
    },
  },
  async created() {
    let date = new Date()
    let bulan = date.getMonth() + 1
    bulan = bulan >= 10 ? bulan : '0' + bulan
    let tahun = date.getFullYear()
    let from = `${tahun}-${bulan}-01`
    this.from = new Date(from).toISOString().substr(0, 10)
    if (this.report_purchase.length) {
      this.loading = false
    }
  },

  async mounted () {
    await this.go('branch','branchs')
    this.branch_id = this.user.branch_id
    await this.go_data()
  }
}
</script>
